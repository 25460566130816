.bg-primary-image {
  background-image: url(./assets/images/background-A.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.my-auto {
  z-index: 9;
}

.swal2-styled.swal2-confirm {
  background-color: #004993 !important;
}
.paper {
  border-radius: 18px !important;
}

.card-shadow {
  -webkit-box-shadow: 0px 0px 16px -8px rgba(32, 26, 26, 0.75);
  -moz-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.75);
}

a[href^="http://maps.google.com/maps"]
{
  display: none !important;
}
a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}
.gmnoprint div {
  background: none !important;
}

.swal2-title {
  font-size: 0.82em !important;
}

/* ::-webkit-scrollbar {
  width: 0;
  background: transparent;
} */
/* Optional: show position indicator in red */
/* ::-webkit-scrollbar-thumb {
  background: transparent;
} */
.otpGroup {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  color: #000000;
}
.OtpInputStyle {
  border: 1px solid #004993;
  border-radius: 10px;
  justify-content: center;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  line-height: 1;
  background-color: #e4e6e9;
}
