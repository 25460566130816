.pagination {
  display: flex;
  justify-content: space-between;
  list-style: none;
  cursor: pointer;
  width: 5rem;
}
.pagination > a {
  padding: 10px;
  border: 1px solid transparent;
  color: #505050;
}

.paginationLink {
  font-weight: bold;
}

.paginationLink:hover {
  background-color: rgb(209, 202, 202);
}

.paginationActive > a {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  background: #004993;
  color: white;
}

.paginationDisabled > a {
  color: #cacaca;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}
