.term-heading {
  text-align: 'center';
  position: 'relative';
  top: '40px';
  line-height: '60 px';
}
.headings {
  color: #004993;
}
tr {
  /* border:1px solid black; */
}
table {
  border: 1px solid black;
  border-collapse: collapse;
}
th {
  border: 1px solid black;
  text-align: center;
}
td {
  border: 1px solid black;
  text-align: center;
}
.li-heading {
  color: #004993;
}

li {
  margin-bottom: 10px;
}
.main-conatiner-priv-policy {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  overflow-x: hidden !important;
  /* padding: 10px; */
}
.sub-conatiner-priv-policy {
  text-align: center;
}
.priv-policy-main-head {
  color: #6f6f6f;
  font-size: 28px;
}
.priv-policy-sub-head {
  color: #004993;
}
.priv-policy-pcondition {
  color: #004993;
  text-decoration: underline;
}
.priv-policy-version {
}
@media screen and (max-width: 715px) {
  .priv-policy-main-head {
    font-size: 20px;
  }
  .priv-policy-sub-head {
    font-size: 18px;
  }
  .priv-policy-pcondition {
    font-size: 18px;
  }
  .priv-policy-version {
    font-size: 16px;
  }
}
@media screen and (max-width: 515px) {
  .priv-policy-main-head {
    font-size: 16px;
  }
  .priv-policy-sub-head {
    font-size: 14px;
  }
  .priv-policy-pcondition {
    font-size: 14px;
  }
  .priv-policy-version {
    font-size: 12px;
  }
}
@media screen and (max-width: 415px) {
  .priv-policy-main-head {
    font-size: 14px;
  }
  .priv-policy-sub-head {
    font-size: 12px;
  }
  .priv-policy-pcondition {
    font-size: 12px;
  }
  .priv-policy-version {
    font-size: 10px;
  }
}
